import * as type from '../actions/types';
import { getAppConfig } from '../constants/appConfig';
import {
  calculateStatusForOffer,
  calculateStatusForDeal,
  isTakeawayDeal,
} from '../data/models/Deal';
import { formatTimeFromInt, findObjectByProperty } from '../utils/helpers';

const initialState = {
  data: [],
  fetching: true,
  error: false,
  errorMessage: '',
  shouldFetch: true,
  goalId: null,
};

const appConfig = getAppConfig();

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    case type.FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_SUCCESS: {
      const { offers } = action.payload;

      // Set a status
      const getStatus = (offer) => calculateStatusForOffer(offer);

      const updatedData = offers.map((offer) => {
        const startTime = formatTimeFromInt(offer.startTime);
        const endTime = formatTimeFromInt(offer.endTime);

        const deals = offer.deals.filter((deal) => {
          if (appConfig.takeawayBlocked) {
            return !isTakeawayDeal(deal);
          }

          return true;
        });

        return {
          ...offer,
          id: offer?.deals?.map((deal) => deal?.objectId).join('_'),
          startTimeReadable: startTime,
          endTimeReadable: endTime,
          status: getStatus(offer),
          deals,
        };
      });

      return {
        ...state,
        data: updatedData,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }
    case type.FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };
    case type.DISABLE_OFFER_FOR_DATE_SUCCESS:
      const { offerIds, dealIds, changedOffer } = action.payload;

      // Loop through all the deals in the (right) offer, and set them as disabled
      const updatedData = state.data.map((offer) => {
        if (offer?.id && offerIds?.includes(offer?.id)) {
          const updatedDeals = offer?.deals.map((deal) => {
            if (dealIds.includes(deal?.objectId)) {
              const dealApiData = findObjectByProperty(changedOffer?.deals, deal?.objectId);
              return {
                ...deal,
                disabledForDate: dealApiData?.disabledForDate,
                enabled: dealApiData?.enabled,
                status: 'disabled',
              };
            }

            return deal;
          });

          const newOffer = {
            ...offer,
            deals: updatedDeals,
          };

          return {
            ...newOffer,
            status: calculateStatusForOffer(newOffer),
          };
        }

        return offer;
      });

      return {
        ...state,
        data: updatedData,
        fetching: false,
        success: true,
      };
    case type.DELETE_OFFER_SUCCESS:
      const deletedOfferId = action.payload.offerId;

      const deleteUpdatedData = state.data.filter((offer) => offer?.id !== deletedOfferId);

      return {
        ...state,
        data: deleteUpdatedData,
        fetching: false,
        success: true,
      };

    case type.RE_ENABLE_OFFER_FOR_DATE_SUCCESS:
      const updatedReEnabledData = state.data.map((offer) => {
        if (offer?.id && action.payload.offerIds?.includes(offer?.id)) {
          const updatedDeals = offer?.deals.map((deal) => {
            if (action.payload.dealIds.includes(deal?.objectId)) {
              return {
                ...deal,
                disabledForDate: null,
                status: calculateStatusForDeal(deal),
              };
            }

            return deal;
          });

          const newOffer = {
            ...offer,
            deals: updatedDeals,
            disabledForDate: null,
          };

          return {
            ...newOffer,
            status: calculateStatusForOffer(newOffer),
          };
        }

        return offer;
      });

      return {
        ...state,
        data: updatedReEnabledData,
        fetching: false,
        success: true,
      };

    case type.DELETE_ALL_OFFERS_SUCCESS:
      return {
        ...state,
        data: [],
        fetching: false,
        success: true,
      };

    // TODO this is a bit redundant. Should have the deal in a single location if possible
    case type.DISABLE_DEAL_SUCCESS:
      const { disabledDeal } = action.payload;

      // Update the changed deal locally
      const updatedOffers = state.data.map((offer) => {
        const updatedDeals = offer?.deals.map((deal) => {
          if (deal?.objectId && deal?.objectId === disabledDeal?.objectId) {
            return {
              ...deal,
              disabledForDate: disabledDeal?.disabledForDate,
              enabled: disabledDeal?.enabled,
              status: 'disabled',
            };
          }

          return deal;
        });

        return {
          ...offer,
          deals: updatedDeals,
        };
      });

      return {
        ...state,
        fetching: false,
        data: updatedOffers,
      };

    default:
      return state;
  }
};
