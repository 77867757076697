/* eslint-disable import/prefer-default-export */
import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { bookingsForRestaurant } from '../graphql/queries';
import { devLog } from '../utils';
import { getAppConfig } from '../constants/appConfig';

const fetchBookings = (restId, region, startDate, endDate, bookingType, userId, userType) =>
  new Promise((resolve, reject) => {
    const paginatedBookings = [];

    const bookingsLoop = async (pageSize, offset) => {
      try {
        const response = await API.graphql(
          {
            query: bookingsForRestaurant,
            variables: {
              restId,
              region,
              pageSize,
              offset,
              startDate,
              endDate,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
            'ec-country': getAppConfig().ecCountryCode,
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        // destructure
        const {
          data: { bookingsForRestaurant: bookings },
        } = response;

        if (!bookings) {
          throw new Error('transactions null');
        }

        // congregate data
        if (bookings.length > 0) {
          paginatedBookings.push(...bookings);
        }

        // But wait, there's more!
        if (bookings.length >= pageSize) {
          bookingsLoop(pageSize, offset + pageSize);
          return;
        }

        devLog('success', bookingType, paginatedBookings);

        // Success resolve
        resolve({ [bookingType]: paginatedBookings });
      } catch (error) {
        devLog('error', bookingType, error);

        reject(error);
      }
    };
    bookingsLoop(500, 0);
  });

export const fetchTransactionBookingsAction =
  (restId, region, startDate, endDate, userId, userType) => async (dispatch) => {
    dispatch({
      type: type.FETCH_TRANSACTION_BOOKINGS_PENDING,
    });

    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'TRANSACTION_BOOKINGS',
    });

    try {
      const response = await fetchBookings(
        restId,
        region,
        startDate,
        endDate,
        'transactions',
        userId,
        userType,
      );

      devLog('success', 'transaction bookings', response);

      dispatch({
        type: type.FETCH_TRANSACTION_BOOKINGS_SUCCESS,
        payload: {
          bookings: response.transactions,
        },
      });
    } catch (error) {
      devLog('error', 'transaction bookings', error);
      dispatch({
        type: type.FETCH_TRANSACTION_BOOKINGS_FAILURE,
        payload: `Unable to retrieve transactions: ${error.message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'TRANSACTION_BOOKINGS',
      });
    }
  };

export const setFilterAction = (newValue) => (dispatch) => {
  dispatch({
    type: type.SET_TRANSACTIONS_FILTER,
    payload: newValue,
  });
};
