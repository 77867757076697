/**
 * https://stackoverflow.com/a/53749034
 *
 * @example
 * getCurrencySymbol('en-US', 'CNY') // CN¥
 * getCurrencySymbol('zh-CN', 'CNY') // ￥
 */
export const getCurrencySymbol = (locale, currency) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
