import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      margin: '0 auto 0',
      display: 'flex',
      flexDirection: 'column',
      gap: '40px',
      backgroundColor: theme.colors.pageBackground,
      minHeight: '100dvh',
      letterSpacing: 'normal',
    },
    header: {
      height: '80px',
      borderBottom: '1px solid #434343',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: '32px',
      marginRight: '32px',
      flexShrink: 0,
      gap: '12px',
    },
    headerLogo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '126px',
      cursor: 'pointer',
    },
    paper: {
      maxWidth: '500px',
      margin: '80px auto 0',
      padding: '40px',
    },
    form: {
      // margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    textField: {
      marginBottom: '24px',
    },
    logo: {
      width: '200px',
      display: 'block',
      margin: '0 auto',
      marginBottom: '24px',
    },
    signInWrapper: {
      padding: '0 32px',

      [theme.breakpoints.down('sm')]: {
        padding: '0 32px',
        height: '100dvh',
      },
    },
    signInContentOuter: {
      maxWidth: '924px',
      position: 'relative',
      margin: '0 auto 0',
    },
    signInContent: {
      borderRadius: '36px',
      border: `1px solid ${theme.colors.cloud}`,
      maxWidth: '924px',
      margin: '0 auto 0',
      width: '100%',
      padding: '40px',
      gap: '20px',
      display: 'flex',
      backgroundColor: 'white',
      justifyContent: 'space-between',

      // [theme.breakpoints.down('md')]: {
      //   padding: '20px',
      // },

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        backgroundColor: theme.colors.pageBackground,
        border: 'none',
        padding: '0',
        // height: '100dvh',/*  */
        height: '100%',
        // justifyContent: 'space-between',
      },
    },
    contentInner: {
      width: '100%',
      maxWidth: '380px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      fontSize: '15px',
      minHeight: '380px',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        // height: '100dvh',
        height: '100%',
        justifyContent: 'space-between',
      },
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      justifyContent: 'space-between',
      height: '100%',
      flexShrink: 0,

      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        justifyContent: 'space-between',
        gap: '8px',
      },
    },
    title: {
      fontSize: '44px',
      fontWeight: '500',
      maxWidth: '314px',
      lineHeight: '1.4',
      margin: 0,
      padding: 0,
      marginTop: '-12px', // Bringing it up a tad makes it look better

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        lineHeight: '1.2',
      },
    },
    helperText: {
      color: theme.colors.smoke,
      fontSize: '15px',
      // display: 'flex',
      gap: '4px',
    },
    bottom: {
      display: 'flex',
      gap: '28px',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        padding: '40px 0',
      },
    },
  }),
  { name: 'CustomSignIn' },
);

export default useStyles;
