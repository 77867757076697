import { Box } from '@eatclub-apps/ec-component-library';
import React, { Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from './Routes';

// Routes
import NotFound from '../components/notFound/NotFound';
import { getAppConfig } from '../constants/appConfig';

const SwitchRoutes = () => {
  const appConfig = getAppConfig();
  const user = useSelector((state) => state.user);
  const { restaurant } = useSelector((state) => state.restaurantActive);

  const isPartnerLogin = user.userInfo?.userType === 'partner';

  // NOTE: Do not show Deals page if only platform is whitelabel
  const whitelabel = restaurant.platform === 'whitelabel';
  const { obeeEnabled, premium, ecPayEnabled } = restaurant;

  const getDefaultRoute = () => {
    const searchParams = window.location.search;

    // Hack for a bug in react router where the route path for "/" doesn't match the actual pathname
    if (window.location.pathname !== '/') {
      return window.location.pathname + searchParams;
    }

    if (obeeEnabled) {
      if (premium) {
        return `/livebookings/floorplan${searchParams}`;
      }

      return `/livebookings/runsheet${searchParams}`;
    }

    if (ecPayEnabled) {
      return `/insights${searchParams}`;
    }

    return `/insights${searchParams}`;
  };

  return (
    <Switch>
      {/* Default route, not necessarily analytics */}
      <Route exact path={['/', '/index.html']}>
        <Suspense fallback={<Box />}>
          <Redirect to={getDefaultRoute()} />
        </Suspense>
      </Route>
      {routes
        .filter((route) => {
          if (appConfig.blockedRoutes.includes(route.path)) {
            return false;
          }

          return true;
        })
        .map((route) => {
          if (route.blockWhiteLabel && whitelabel) {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                <Suspense fallback={<Box />}>
                  <Redirect to='/' />
                </Suspense>
              </Route>
            );
          }

          if (route.blockPartners && isPartnerLogin) {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                <Suspense fallback={<Box />}>
                  <Redirect to='/' />
                </Suspense>
              </Route>
            );
          }

          if (route.ecPayEnabledRequired && !ecPayEnabled) {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                <Suspense fallback={<Box />}>
                  <Redirect to='/' />
                </Suspense>
              </Route>
            );
          }

          if (route.obeeEnabledRequired && !obeeEnabled) {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                <Suspense fallback={<Box />}>
                  <Redirect to='/livebookings' />
                </Suspense>
              </Route>
            );
          }

          if (route.obeePremiumRequired && !premium) {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                <Suspense fallback={<Box />}>
                  <Redirect to='/livebookings' />
                </Suspense>
              </Route>
            );
          }

          // venues have no use for this page once on premium
          if (route.path === '/livebookings' && obeeEnabled && premium) {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                <Suspense fallback={<Box />}>
                  <Redirect to='/livebookings/runsheet' />
                </Suspense>
              </Route>
            );
          }

          return (
            <Route key={route.path} exact={route.exact} path={route.path}>
              <Suspense fallback={<Box />}>
                <route.main />
              </Suspense>
            </Route>
          );
        })}
      {/* Route doesnt exist in Routes */}
      <Route path='*'>
        <Suspense fallback={<Box />}>
          <NotFound />
        </Suspense>
      </Route>
    </Switch>
  );
};

export default SwitchRoutes;
