import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { getAppVersion, isEmpty, only } from '../utils/helpers';
import * as type from './types';
import { affiliatedRestaurants, restaurantById, restaurantSearchByName } from '../graphql/queries';
import { devLog } from '../utils';
import { makeApiAction } from './actions';
import {
  saveAverageBookingValue,
  saveAverageOrderValue,
  saveFoodCostPercent,
  saveRestaurant,
} from '../graphql/mutations';
import { RestaurantInputKeys } from '../data/models/Restaurant';
import { getAppConfig } from '../constants/appConfig';

const ausTimezones = [
  'Antarctica/Macquarie', // Unexpected
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/Perth',
  'Australia/Sydney',
];

/**
 * Sets the timezone for all future dayjs instances
 * @param {string} region
 */
const setDayjsTimezone = (region) => {
  if (isEmpty(region)) {
    dayjs.tz.setDefault();
  }

  // set the default timezone to restaurant's region
  const newTimezone = ausTimezones.find((zone) => zone.toLowerCase().match(region.toLowerCase()));
  if (newTimezone) {
    dayjs.tz.setDefault(newTimezone);
  } else {
    // default to the local browser timezone
    dayjs.tz.setDefault();
  }
};

export const fetchRestaurantAction = (restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'RESTAURANT',
  });

  const getData = async () => {
    try {
      const response = await API.graphql(
        {
          query: restaurantById,
          variables: {
            restId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
          'ec-country': getAppConfig().ecCountryCode,
        },
      ).catch((error) => {
        throw new Error(error);
      });

      devLog('success', 'restaurant', response.data.restaurantById);

      const region = response.data.restaurantById?.region;
      setDayjsTimezone(region);
      const todayByTimezone = dayjs().weekday();

      dispatch({
        type: type.FETCH_RESTAURANT_SUCCESS,
        payload: {
          restaurant: response.data.restaurantById,
          todayByTimezone,
        },
      });
    } catch (error) {
      devLog('error', 'restaurant', error);

      dispatch({
        type: type.FETCH_RESTAURANT_FAILURE,
        payload: `Unable to retrieve Restaurant: ${error.message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'RESTAURANT',
      });
    }
  };
  getData();
};

export const fetchRestaurantsByNameAction = (name, userId, userType) => (dispatch) => {
  dispatch({
    type: type.FETCH_RESTAURANTS_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: restaurantSearchByName,
          variables: {
            name,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'app-version': getAppVersion(),
          'ec-country': getAppConfig().ecCountryCode,
        },
      );

      devLog('success', 'restaurants', response.data.restaurantSearchByName);

      dispatch({
        type: type.FETCH_RESTAURANTS_SUCCESS,
        payload: response.data.restaurantSearchByName,
      });
    } catch (error) {
      devLog('error', 'restaurants', error);

      dispatch({
        type: type.FETCH_RESTAURANTS_FAILURE,
        payload: `Unable to retrieve Restaurants: ${error.errors[0].message}`,
      });
    }
  })();
};

export const clearRestaurantsByNameAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_RESTAURANTS,
  });
};

export const saveRestaurantAction = (updatedRestaurant) => (dispatch) => {
  const action = 'SAVE_RESTAURANT';

  const updatedRestaurantData = only(updatedRestaurant, RestaurantInputKeys());

  const variables = {
    restaurant: updatedRestaurantData,
  };

  dispatch(
    makeApiAction(
      action,
      saveRestaurant,
      variables,
      null,
      'saveRestaurant',
      true,
      dispatch,
      'Your profile has been saved',
      null,
      false,
      'Unable to save profile',
    ),
  );
};

export const fetchAffiliatedRestaurantsAction = (restId) => (dispatch) => {
  const action = 'FETCH_AFFILIATED_RESTAURANTS';

  // Note - rest ID is needed to be passed in since we're using the user ID not active restaurant ID
  const variables = { restId };

  dispatch(
    makeApiAction(
      action,
      affiliatedRestaurants,
      variables,
      null,
      'affiliatedRestaurants',
      false,
      dispatch,
      null,
      null,
      false,
      'Unable to retrieve affiliated restaurants',
    ),
  );
};

/**
 * @param {number} abv "Average booking value"
 */
export const saveAverageBookingValueAction = (abv) => (dispatch) => {
  // note: using Average order value instead of Average booking value terminology in the api messaging to avoid clashing with ECBookings terminology

  dispatch(
    makeApiAction(
      'UPDATE_AVERAGE_BOOKING_VALUE',
      saveAverageBookingValue,
      { abv },
      {},
      'saveAverageBookingValue',
      true,
      dispatch,
      'Average order value updated.',
      null,
      false,
      'Unable to update average order value.',
    ),
  );
};

/**
 * @param {number} aov "Average order value"
 */
export const saveAverageOrderValueAction = (aov) => (dispatch) => {
  dispatch(
    makeApiAction(
      'UPDATE_AVERAGE_ORDER_VALUE',
      saveAverageOrderValue,
      { aov },
      {},
      'saveAverageOrderValue',
      true,
      dispatch,
      'Average order value updated.',
      null,
      false,
      'Unable to update the average order value.',
    ),
  );
};

export const updateFoodCostPercentAction = (newFoodCost) => (dispatch) => {
  const action = 'UPDATE_FOOD_COST_PERCENT';

  const variables = {
    foodCostPercent: newFoodCost,
  };

  dispatch(
    makeApiAction(
      action,
      saveFoodCostPercent,
      variables,
      { foodCostPercent: newFoodCost },
      'saveFoodCostPercent',
      true,
      dispatch,
      null,
      null,
      true,
      'Unable to update food cost',
    ),
  );
};
