const localeConfigs = {
  au: {
    ecCountryCode: 'AU',
    locale: 'en-AU',
    currency: 'AUD',
    minDiscount: 15,
    blockedRoutes: [],
    whiteLabelBlocked: false,
    opposBlocked: false,
    goalsBlocked: false,
    takeawayBlocked: false,
  },
  uk: {
    ecCountryCode: 'UK',
    locale: 'en-UK',
    currency: 'GBP',
    minDiscount: 25,
    blockedRoutes: [
      '/oppo/:oppoId',
      '/alloppos/:restId',
      '/booking-confirm/:bookingId',
      '/booking-call/:bookingId',
      '/analytics',
      '/billing',
      '/menus',
      '/livebookings',
      '/livebookings/runsheet',
      '/livebookings/floorplan',
      '/livebookings/floorplan/edit',
      '/livebookings/timeline',
      '/livebookings/monthly',
      '/livebookings/guestbook',
      '/livebookings/settings',
      '/livebookings/integrations',
      '/livebookings/integrations/fbmarketplace',
      '/goals/previous',
      '/goals/create',
      '/goals/edit/:goalId',
      '/goals/:goalId',
    ],
    whiteLabelBlocked: true,
    opposBlocked: true,
    goalsBlocked: true,
    takeawayBlocked: true,
  },
};

export const getAppConfig = () => localeConfigs[import.meta.env.VITE_LOCALE] ?? localeConfigs.au;
